table {
 font-size: large;
  border-collapse: collapse;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.tauluDiv{
    background-color: #532323;
    max-width: 0px;
}
.nyt {
    font-size: 30px;
    font-weight: 800;
    height: auto;   
    text-align: center;
}

.palvelutDiv{
    width: 100%;
    height: 100%;
    float: left;
    background-color: #fff;
    margin-bottom: 20px;
}


@media screen and (max-width: 767px) {

table {
        width: 96%;
        margin: 2%;
        border-collapse: collapse;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
.nyt{
    font-size: 30px;
    font-weight: 800;
    height: auto;   
    text-align: center;
}

    th
    {
         text-align: center;
        font-size: 13;
    }

    td{
  
        border: 1px solid #050000;
        font-size: 14px;
        font-weight: 550;
    }

    tr:nth-child(even) {
        background-color: #dddddd;
    }
        .divvq {
    
            background-color: #ffffff;
            padding-bottom: 3%;
        }

}

