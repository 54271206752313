.gallery{
    height: 100%;
    display: block;
    margin: auto;
    max-height: 350px;
    max-width: 100%;
    padding: 2%;
    text-align: center;
}
    

.gallery:hover{

    transform: scale(1.02);
}

.galleryDiv1{
    margin: 10px;
    float: left;
    max-height: 80%;
    width: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.galleryMainDiv{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: 75%;
    background-color: #fff;
}




@media screen and (max-width: 768px){
    .gallery{
        height: 100%;
        width:100%;
        margin: auto;
        padding: 3%;
    }

.gallery:hover{

    transform: scale(1.02);
}

.galleryDiv1{
    margin: 10px;
    float: left;
    max-height: 70%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.galleryMainDiv{
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 10px;
    width: 90%;
    height: 100%;
    margin: auto;
    max-width: 75%;
    background-color: #fff;
    margin-bottom: 10px;
}
}