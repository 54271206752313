
.BigDiv
{
    height: 20;
    width: 100%;
    float: left;
    padding-top: 20%;
    text-align: center;
    background-color: rgba(194, 194, 216, 0); 
    margin-bottom: -6px;
    z-index: 2;

}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    /* margin-right: auto; */
    /* margin-left: auto; */
}
.logo{
    margin-left: 10px;
    height: auto;
}


.ImageLogo1 {
    width: 50%;
    background-color: rgba(113, 84, 103, 0);
    float: left;
    z-index: 1;
}

.ImageLogoDiv {
    width: 100%;
    height: 600px;
    background-color: rgb(25, 25, 255);
}


.ImageDiv{
    position: fixed;
    z-index: -3;
    width: 100%;
    height: 50%;
    float: left;
}

.logo{
    margin-top: 20px;
    max-height: 500px;
}

.tyoKuvaHome
{
    width: 100%;
    z-index: 1;
    filter:opacity(0.4);
}

.TextDiv
{
    padding-top: 5%;
    padding-bottom: 5%;
    font-size: xx-large;
    font-weight: bold;
    text-align: center;
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    z-index: 1;
}


.WelcomeText
{
    text-align: center;
    padding-top: 10%;
    font-size: 75px;
    font-weight: 700;
    color: rgb(19, 16, 16);
}

.RadiusImage
{
    width: 100%;
    margin-bottom: 0px;
}

.Footerr
{
    background-color: rgba(0, 0, 0, 0.81);
}
.FormDiv
{
    float: left;
    padding-top: 20px;
    width: 100%;
    background-color: #fff;
    height: auto;
    display: flex;
justify-content: center;
}


.test{
    background-color: #fff;
}
@media screen and (max-width: 767px) {

    
    .BigDiv{
        padding-top: 25%;
    }
    h3{
        font-size: 23px;
        font-weight: 550;
    }

         .ImageLogo {
            height: auto;
            font-size: small;
            width: 100%;
            float: left;
            background-color: rgba(255, 255, 255);
            z-index: -1;
         }
         .Biglogo
         {
            width: 100%;
         }
        .FormDiv {
            width: 100%;
            padding: 0px;
            background-color: #fff;
            height: auto;
        }

        .WelcomeText {
            padding-top: 30%;
            font-size: 30px;
            font-weight: 500;
            color: rgb(255, 255, 255);
        }
        .NappiSoitta {
            font-size: 15px;
            padding-left: 30px;
            padding-right: 30px;
        }      
        
        .test{
            background-color: rgb(255, 255, 255);
            padding-bottom: 20px;
            margin-bottom: -10px;
        }
        .Footerr{
            margin-top: -10px;
            margin-bottom: -20px;
        }

                .WelcomeText {
                    font-size: 55px;
                    font-weight: 800;
                    color: rgb(255, 255, 255);
                }


                .galleryDiv{
                    width: 100%;
                    height: 700px;
                    padding-inline: 5%;
                    background-color: #fff;
                }
                .container {
                    width: 100%;
                    padding-right: 15px;
                    padding-left: 15px;
                    /* margin-right: auto; */
                    /* margin-left: auto; */
                }

}

@media screen and (max-width: 1247px) {
    .logo{
        margin-top: 50px;
        max-width: 45%;
    }
    .formDivContactPage{
        background-color: #fff;
        width: 100%;
    }
    .logo{
        margin-top: 0px;
        max-height: 0px;
    }

    .formMAindiv {
        background-color: #fff;
        width: 90%;
        height: 50%;
        margin-top: auto;
        text-align: center;
    }

    .Yhteys {
        margin-top: 20px;
        font-size: 30px;
        font-weight: 800;
        text-align: center;
    }

    .upForm {
        height: auto;
        width: 95%;
        max-width: 850px;
        background-color: #dddddd;
        padding-bottom: 50px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }


    .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        /* margin-right: auto; */
        /* margin-left: auto; */
    }
}