.navbar-nav .nav-link {
    /* padding-right: 5px; */
    padding-left: 12px;
}

.naviBar
{
    position: fixed;
    z-index: 1;
    padding-left: 0px;
    width: 100%;
    background-color: #0b0a17c1;
    box-shadow: 10px rgba(0, 0, 0, 0.9);
    font-size: 20px;
}
.container {
    width: 90%;
    padding-right: 15px;
    padding-left: 15px;
    /* margin-right: auto; */
    /* margin-left: auto; */
}


.navbartext
{
    padding: 10px;
    color: rgb(0, 0, 0);

}

.Logo {
    box-shadow: 3px 3px 3px 3px rgb(0, 0, 0, 0.5);
    margin-bottom: 1px;
    margin-left: 10px;
    width: 90px;
   
}

.navbar-toggler
{
    justify-content: flex-end;
    margin-left: 10px;
    margin-top: 10px;
    border: 2px solid rgb(240, 248, 255);
}

.navbar-toggler-icon
{

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28240, 248, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.pName{
    margin-top: 10px;
    font-size: 19px;
    font-weight: 550;
    float: right;
    color: 'white';
}

.mr-auto, a
{
    color: aliceblue;
}

@media screen and (max-width: 767px ) {
        .Logo {
                box-shadow: 3px 3px 3px 3px rgb(0, 0, 0, 0.5);
                    margin-bottom: 0px;
                    margin-right: 10px;
                    width: 70px;
            }
}

/*   
<button aria-controls="responsive-navbar-nav" color="blue" type="button" aria-label="Toggle navigation" class="navbar-toggler collapsed"><span class="navbar-toggler-icon"></span></button>

*/