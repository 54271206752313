.PageTop{

    padding-top: 200px;
}

.logoForm{
    float: left;
}


.formDivContactPage{
    float: left;
    width: 50%;
    height: 100%;
}


@media screen and (max-width: 767px) {

    .logo{
        width: 100%;
    }


    .formDivContactPage{
        float: left;
        width: 99%;
        height: 100%;
    }
}