.formMAindiv{
    background-color: #fff;
    width: 100%;
    height: 50%;
    margin-top: auto;
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;

}
.mainText{

    text-align: center;
    width: 100%;
    max-width: 600px;
}
.Yhteys{
    margin-top: 20px;
    font-size: xx-large;
    font-weight: 800;
}


.upForm{
    padding: 1%;
    height: auto;
    width: 95%;
    max-width: 850px;
    margin-left: 17px;
    background-color: #dddddd;
    padding-bottom: 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Inputik{
    border: #dddddd;
    width: 100%;
    padding-bottom: 1px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(12, 1, 1);
}

.inputNamePhone{
    border: #dddddd;
    width: 99%;
    border-bottom: 1px solid rgb(12, 1, 1);
    
}
.someLogos{
    float: right;
    height: 39px;
}
.labelHalf{
    width: 50%;
}

.NappiSoitta1 {
    float: right;
    padding-left: 10px;
    padding-right: 15px;
    font-size: 20px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.NappiSoitta1:hover {
    color: #fff;
    background: #3a3d3f;

}
.someLogos{
    float: right;
    height: 37px;
    margin-right: 10px; 
    margin-top: -2px;
}


.LabelP{
    font-size: 12px;
    margin-bottom: 1px;
    text-align: left;
}



@media screen and (max-width: 767px) {
    .formMAindiv{
        background-color:#fff;
        width: 100%;
        height: 50%;
        float: left;
        padding-left: -10%;
        text-align: center;
    
    }
    .mainText{
        text-align: center;
        width: 60%;
    }
    .Yhteys{
        font-size: x-large;
        font-weight: 600;
    }
    
    
    .upForm{
        height: auto;
        width: 90%;
        background-color: #dddddd;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .Inputik{
        border: #dddddd;
        width: 100%;
        padding-bottom: 1px;
        border-bottom: 1px solid rgb(12, 1, 1);
    }
    
    .inputNamePhone{
        border: #dddddd;
        width: 100%;
        border-bottom: 1px solid rgb(12, 1, 1);
        
    }
    .someLogos{
        float: right;
        height: 39px;
    }
    .labelHalf{
        width: 100%;
    }
    .labelFull{
        height: 20px;
        width: 100%;
    }
    
    .NappiSoitta1 {
        float: right;
        padding-left: 10px;
        padding-right: 15px;
        font-size: 16px;
        margin-right: 1px;
        margin-bottom: 10px;
    }
    
    .NappiSoitta1:hover {
        color: #fff;
        background: #3a3d3f;
    
    }
    .someLogos{
        float: right;
        height: 32px;
        margin-right: 10px; 
        margin-top: -2px;
    }
    
    
    .LabelP{
        font-size: 12px;
        margin-bottom: 1px;
        text-align: left;
    }
    .myH5{
        font-size: medium;
        font-weight: 500;
    }
    
}