p
{
    font-size: x-large;
    font-weight: 600;

}

.test{

    font-size: xx-large;
}


@media screen and (max-width: 767px) {

    .test{
        padding-top: 22%;
padding-inline: 2%;
    }
    p
{
    font-size: medium;
    font-weight: 500;

}
}