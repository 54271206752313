.Footer {
    width: 100%;
    height: auto;
    background-color: rgba(55, 54, 51, 0.5);
    font-weight: 600;
    padding-left: 10px;
    margin-bottom: 0px;
    height: auto;
    color: azure;
    float: left;
    background-color: rgb(70, 70, 79);
    
}

.pNavBar{
    font-size:  14px;
    float: left;
    font-weight: 200;
}

.autocolums{
    column-count: 2;
}
@media screen and (max-width: 767px) {
    .Footer {
            margin-top: 0px;
            width: 100%;
            font-size: small;
            height: auto;
            background-color: rgb(70, 70, 79);
            margin-bottom: -1px;
        }

        .pNavBar{
            font-size:  11px;
            font-weight: 200;
            float: left;
            margin-right: 10px;
     
        }
}